import React, { Component } from "react";
import propTypes from "prop-types";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: null,
      focused: false,
      isLoading: false,
      dealerships: [],

      // filter information
      monthSelected: "",
      dealerSelected: {},
      dealerName: ""
    };
    this.getDealerships = this.getDealerships.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  getDealerships(value = {}) {
    this.setState({
      isLoadingBranches: true,
      dealerName: value.branch_name
    });
    this.props
      .onSearch(value)
      .then(dealerships => {
        this.setState({
          isLoading: false
        });
      })
      .catch(err => {
        console.log(`Error exit1: ${err}`);
      });
  }

  handleSelect = values => {
    this.setState(
      {
        focused: false,
        dealerSelected: { dealership_id: values.id, dealername: values.name },
        dealerName: `${values.id}: ${values.name}`
      },
      () => {
        return this.CheckSubmit();
      }
    );
  };
  renderList = () => {
    const { dealerships } = this.state;
    if (dealerships && dealerships.length > 0) {
      return (
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {dealerships.map((item, index) => {
              return (
                <a
                  href=""
                  key={item.id}
                  onClick={event => {
                    event.preventDefault();
                    this.handleSelect(item);
                  }}
                  className="dropdown-item"
                >
                  {`${item.id}: ${item.name}`}
                </a>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <span className="dropdown-item">{"ไม่พบข้อมูล"}</span>
          </div>
        </div>
      );
    }
  };
  handleOutsideClick(e) {
    if (this.branchPicker && this.branchPicker.contains(e.target)) {
      return;
    }
    this.handleClick();
    this.setState({
      focused: false
    });
  }

  handleClick() {
    if (this.state.focused) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
  }
  CheckSubmit() {
    if (this.state.monthSelected && this.state.dealerSelected.dealership_id) {
      this.handleSubmit();
    }
  }
  handleSubmit() {
    this.props.onSubmit({
      month: this.state.monthSelected,
      dealership_id: this.state.dealerSelected.dealership_id
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.dealerships) {
      this.setState({
        dealerships: nextProps.dealerships
      });
    }
  }
  render() {
    const { focused, branches, dealerName, dealerships } = this.state;
    return (
      <div className="tile is-ancestor is-12 is-mobile">
        <div className="tile is-parent ">
          <article className="tile is-child box">
            <p className="title">รายการเรียกเก็บรายเดือน</p>
            <div className="content">
              <div className="tile is-5 is-vertical">
                <div
                  className="field dropdown is-active"
                  ref={node => {
                    this.branchPicker = node;
                  }}
                >
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="email"
                      placeholder="Dealership"
                      value={dealerName}
                      onBlur={this.handleClick()}
                      onFocus={() => {
                        this.setState({
                          focused: true
                        });
                      }}
                      onChange={e => {
                        this.getDealerships({
                          id: e.target.value,
                          name: e.target.value
                        });
                      }}
                    />
                    {focused && dealerships && this.renderList()}
                    <span className="icon is-small is-left">
                      <i className="fas fa-store" />
                    </span>
                  </div>
                </div>

                <div className="field">
                  <div className="control has-icons-left">
                    <MonthPickerInput
                      className="input"
                      type="email"
                      inputProps={{
                        className: "input"
                      }}
                      placeholder="Branches"
                      value={this.state.monthSelected}
                      year={2019}
                      onChange={value => {
                        this.setState(
                          {
                            monthSelected: value
                          },
                          () => {
                            return this.CheckSubmit();
                          }
                        );
                      }}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-calendar" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  }
}
SearchBox.propTypes = {
  onSubmit: propTypes.func.isRequired,
  onSearch: propTypes.func
};
export default SearchBox;
