import React  from 'react'
import ListGroup from './listGroup';
import menu from './menu'
 const MainMenuPanel = ({props})=>{
    return(
        menu.map((value,key)=>{
            return <ListGroup List={value.listItem} key={key} />
        })
    )
}
export default MainMenuPanel