import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'

const reactotron = Reactotron.configure({ name: 'Drivehub Admin' })
.use(reactotronRedux()) //  <- here i am!

if (process.env.NODE_ENV !== 'production') {
  reactotron.connect()
}
export default reactotron
