let base_url = "";
let legacy_url = "";
let admin_api = "";
switch (process.env.REACT_APP_STAGE) {
  case "production":
    base_url = process.env.NODE_ENV;
    admin_api = 'https://api-admin.drivehub.co/api/v1'
    legacy_url = "drivehub.co";
    break;
  case "staging":
    base_url = "dev-drivehub.co/admin";
    admin_api = 'https://api-admin-staging.drivehub.co/api/v1'
    legacy_url = "dev-drivhub.co"
    break;
  default:
    base_url = `http://localhost:${process.env.port}/admin`;
    admin_api = 'http://localhost:8080/api/v1'
    legacy_url = "http://localhost:3000"
}
export const BASE_URL = base_url;
export const LEGACY_URL = legacy_url;
export const ADMIN_API = admin_api;