import React, { Component, Fragment } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  HashRouter
} from "react-router-dom";
// initialize bulma css
import "bulma/css/bulma.css";
import Header from "./components/organism/header";
import MainMenuPanel from "./components/organism/mainmenu";
import routes from "./config/routes";
import MonthlyBilling from "./pages/monthlyBillingPage";
const Admins = ({ match }) => {
  return (
    <Fragment>
      <Header />
      <div className="container is-fluid">
        <div className="columns is-mobile">
          <div className="column is-2">
            <MainMenuPanel />
          </div>
          <div className="column is-10">
            <Route
              component={MonthlyBilling}
              path={`${match.path}/monthly_billing`}
            />
            <Route
              path={match.path}
              exact
              render={() => <h3>Please select a topic.</h3>}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Route path="/admin" component={Admins} />
        </Router>
      </div>
    );
  }
}

export default App;
