import React from "react"
import { Link } from "react-router-dom"

const ListGroup = ({ List }) => {
  return (
    <nav className="panel">
      {List.map((value, key) =>{
          return(
              <div key={value.name}>
                  {value.type === 'version2' ?  <Link to={value.url} className="panel-block">{value.name}</Link> : <a className="panel-block" href={value.url}>{value.name}</a>}
              </div>
          )
      })}
    </nav>
  );
};

export default ListGroup;
