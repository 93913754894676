import { applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import Reactotron from "./reactotronConfig";
import rootReducer from "./reducers";

export default function configureStore(initialState) {
  const history = createBrowserHistory();
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  let enhancers = [middlewareEnhancer];
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers = [
      middlewareEnhancer,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    ];
  }
  const composedEnhancers = compose(...enhancers);

  const store = Reactotron.createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
  );

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }
  return store;
}
