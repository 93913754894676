export const menu = [
  {
    listItem: [
      {
        url: "/administration/bookings",
        name: "จำนวนการจอง",
        type: "version1"
      },
      {
        url: "/administration/users",
        name: "ผู้ใช้งาน",
        type: "version1"
      },
      {
        url: "/administration/cupons",
        name: "คูปอง",
        type: "version1"
      },
      {
        url: "/administration/leads",
        name: "Leads",
        type: "version1"
      },
      {
        url: "/administration/notifications",
        name: "Notification",
        type: "version1"
      }
    ]
  },
  {
      listItem:[
        {
            url:"/administration/employees",
            name:"Employees",
            type:"version1"
        },
        {
            url:"/administration/branches",
            name:"สาขา",
            type:"version1"
        },
        {
            url:"/administration/dealership",
            name:"ร้านเช่ารถ",
            type:"version1"
        },
        {
            url:"/administration/cities",
            name:"จังหวัด",
            type:"version1"
        },
        {
            url:"/administration/locations",
            name:"สถานที่",
            type:"version1"
        },{
            url:"/administration/cars",
            name:"รถยนต์",
            type:"version1"
        },{
            url:"/administration/car_sub_models",
            name:"Sub Models",
            type:"version1",
        },
        {
            url:"/administration/car_categoriers",
            name:"รูปแบบรถยนต์",
            type:"version1"
        },{
            url:"/administration/customers",
            name:"ลูกค้า",
            type:"version1"
        },{
            url:"/administration/new",
            name:"ส่งคำเชิญลูกค้า",
            type:"version1"
        },{
            url:"/administration/car_bookings/version/reasons",
            name:"Booking Change Reasons",
            type:"version1"
        },{
            url:'/admin/monthly_billing',
            name:"วางบิลรายเดือน",
            type:"version2"
        }
      ]
  }
];
export default menu