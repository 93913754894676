import { defineAction } from "redux-define";
export const FETCH_ACTION = defineAction("DEALERS", [
  "FETCHING",
  "FETCHED",
  "ERROR",
  "SUCCESS",
  "INITIAL",
  "FETCHED_TOTAL_DEALERS"
]);

const initialState = {
  fetching: false,
  error: false,
  dealerships: [],
  total_bookings: 0
};
const reducer = (state = initialState, { type, payload, isNext }) => {
  switch (type) {
    case FETCH_ACTION.INITIAL: {
      return initialState;
    }
    case FETCH_ACTION.FETCHING: {
      return {
        ...state,
        fetching: true,
        error:false,
      };
    }
    case FETCH_ACTION.ERROR: {
      return { ...initialState,error:true, message:payload};
    }
    case FETCH_ACTION.FETCHED: {
       return {
         ...state, 
         error:false,
         fetching:false,
         dealerships:payload,
       
       }
    }
    case FETCH_ACTION.FETCHED_TOTAL_DEALERS: {
      return {
        ...state,
        total_dealer: payload
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
