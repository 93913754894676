import React, { Component } from "react";
import SearchBox from "./ecosystems/SearchBox";
import { bindActionCreators } from "redux";
import { fetchBookings } from "../../redux/actions/bookings";
import { LEGACY_URL, ADMIN_API } from "../../variables/switching";
import { fetchList } from "../../redux/actions/dealerships";
import moment from "moment";
import { connect } from "react-redux";
import DrivehubLoading from "../../components/utils/loading.io/drivehub";
import styled from "styled-components";

const Td = styled.td`
  text-align: right;
`;
const Th = styled.td`
  text-align: center;
`;

const Table = styled.table`
  width: 98%;
  font-size: 14px;
`;
const Tr = styled.tr`
  background-color: #fafafa;
  & > td:first-child {
    text-transform: uppercase;
  }
`;

class MonthlyBillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {},
      vatInclude: false,
      addOn: 0
    };
    this.handleVatChage = this.handleVatChage.bind(this);
    moment.locale("th");
  }
  onSubmit = values => {
    this.props.fetchBookings(values).then(()=>{
     
    })
  };
  onClear = () => {};
  addCommas = intNum => {
    return (intNum + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  };
  handleVatChage = () => {
    this.setState({
      vatInclude: !this.state.vatInclude
    });
  };
  renderConclusion() {
    const { bookings } = this.props;
    const { vatInclude } = this.state;
    if (bookings.branchesBookings.all_branch_summation) {
      return (
        <Table className="table is-bordered is-stripped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <Th colSpan={1}>TOTAL DUE</Th>
              <th>
                <div>
                  <a href={bookings.filedownload} target="_blank" className="button is-small">
                    <span className="icon">
                      <i className="fas fa-file-excel" />
                    </span>
                    <span>Export to excel</span>
                  </a>
                </div>
              </th>
            </tr>
            <tr>
              <th colSpan={2}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    value={vatInclude}
                    onChange={this.handleVatChage}
                  />{" "}
                  Vat include
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            <Tr>
              <td>All branch in total Booking</td>
              <Td>{bookings.branchesBookings.total_bookings}</Td>
            </Tr>
            <Tr>
              <td>All branch in total sales</td>
              <Td>
                {bookings.branchesBookings.all_branch_summation.total_price}
              </Td>
            </Tr>
            <Tr>
              <td>All branch in sub total commission</td>
              <Td>
                {bookings.branchesBookings.all_branch_summation.total_comission}
              </Td>
            </Tr>
            <Tr>
              <td>All branch in total discount</td>
              <Td>
                {bookings.branchesBookings.all_branch_summation.total_discount}
              </Td>
            </Tr>
            <Tr>
              <td>All branch in total commission Due</td>
              <Td>
                {
                  bookings.branchesBookings.all_branch_summation
                    .grand_total_comission
                }
              </Td>
            </Tr>
            {vatInclude && [
              <Tr key={1}>
                <td>All branch in total vat</td>
                <Td>{bookings.branchesBookings.all_branch_summation.vat}</Td>
              </Tr>,
              <Tr key={2}>
                <td>Grand total</td>
                <Td>
                  {
                    bookings.branchesBookings.all_branch_summation
                      .total_comission_include_vat
                  }
                </Td>
              </Tr>
            ]}
          </tbody>
        </Table>
      );
    }
  }
  renderList() {
    const { bookings } = this.props;
    if (bookings.fetching) {
      return <DrivehubLoading />;
    } else if (bookings.error) {
      return (
        <div className="notification is-danger">
          <h3> Error connection failed!</h3>
        </div>
      );
    } else if (
      bookings.sucessfully &&
      bookings.branchesBookings.total_bookings < 1
    ) {
      return (
        <div className="notification is-danger">
          <h3> Error data not found!</h3>
        </div>
      );
    } else {
      return [
        bookings.branchesBookings.branch.map(branch => {
          return (
            <Table
              className="table is-bordered is-stripped is-hoverable is-fullwidth"
              key={branch.branch_id}
            >
              <thead>
                <tr>
                  <th
                    colSpan="13"
                    style={{ backgroundColor: "#e0e0e0" }}
                  >{`สาขา ${branch.branch_id} ${branch.branch_name}`}</th>
                </tr>
                <tr>
                  <Th>#</Th>
                  <Th>{`Booking id`}</Th>
                  <Th>{`Customer's Name`}</Th>
                  <Th>{`โทรศัพท์`}</Th>
                  <Th>{`วันรับรถ`}</Th>
                  <Th>{`เวลา`}</Th>
                  <Th>{`วันคืนรถ`}</Th>
                  <Th>{`เวลา`}</Th>
                  <Th>{`ทะเบียนรถ`}</Th>
                  <Th>{`ราคา`}</Th>
                  <Th>{`คอมมิชชั่น`}</Th>
                  <Th>{`Discount`}</Th>
                  <Th>{`Remark`}</Th>
                </tr>
              </thead>
              <tbody>
                {branch.bookings &&
                  branch.bookings.map((item, key) => {
                    const { metadata } = item;
                    return (
                      <tr key={item.booking_id}>
                        <td>{key + 1}</td>
                        <td>{item.booking_id}</td>
                        <td>
                          {metadata && metadata.customer
                            ? metadata.customer.first_name.concat(
                                " ",
                                metadata.customer.last_name
                              )
                            : item.customer_name}
                        </td>
                        <td>
                          {metadata && metadata.customer ? metadata.customer.phone_number : ""}
                        </td>
                        <td>
                          {moment(
                            item.scheduled_begin,
                            "YYYY-MM-DDTHH:mm:ss"
                          ).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment(
                            item.scheduled_begin,
                            "YYYY-MM-DDTHH:mm:ss"
                          ).format("HH:mm")}
                        </td>
                        <td>
                          {moment(
                            item.scheduled_end,
                            "YYYY-MM-DDTHH:mm:ss"
                          ).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment(
                            item.scheduled_end,
                            "YYYY-MM-DDTHH:mm:ss"
                          ).format("HH:mm")}
                        </td>
                        <td>{item.license_plate}</td>
                        <Td>{item.sale_price}</Td>
                        <Td>{item.commission}</Td>
                        <Td>{item.discount}</Td>
                        <td />
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={9}>รวม</td>
                  <Td>{branch.summation.total_sale_price}</Td>
                  <Td>{branch.summation.total_comission}</Td>
                  <Td>{branch.summation.total_discount}</Td>
                  <td />
                </tr>
                <tr>
                  <td colSpan={9}>ค่าคอมมิสชั่นหลังจากหักรายการส่วนลด</td>
                  <Td colSpan={3}>{branch.summation.grand_total_comission}</Td>
                  <td />
                </tr>
                <tr>
                  <td colSpan={9}>จำนวนการจอง</td>
                  <Td colSpan={3}>{branch.total_bookings}</Td>
                  <td />
                </tr>
              </tfoot>
            </Table>
          );
        }),
        this.renderConclusion()
      ];
    }
  }
  componentDidMount() {
    this.props.fetchList().then(() => {});
  }
  componentWillUpdate(nextProps, nextState) {
    return nextProps.dealerships !== this.props.dealerships;
  }
  render() {
    const { bookings, dealerships, fetchList } = this.props;

    const { grand_total, grand_total_comission, vatInclude } = this.state;
    return (
      <div className="container">
        <SearchBox
          onSubmit={this.onSubmit}
          dealerships={dealerships.dealerships}
          onSearch={fetchList}
        />
        <div className="columns is-mobile">
          <div className="column is-11">{this.renderList()}</div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchBookings, fetchList }, dispatch);
};
const mapStateToProps = state => ({
  bookings: state.bookingList,
  dealerships: state.dealerships
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyBillingPage);
