import React, { Component, Fragment } from 'react'
import {Navbar} from './style'
import Dhlogo from '../../../assets/images/dh-logo.svg'
export default class Header extends Component {
  render() {
    return (
      <Fragment>
        <Navbar role="navigation" aria-label="navigation">
            <div className="container">
                <div className="navbar-brand">
                    <a className="navbar-item" href="https://drivehub.co">
                        <img src={Dhlogo} alt="drivehub-logo" />
                    </a>
                </div>
            </div>
        </Navbar>
      </Fragment>
    )
  }
}
