import axios from "axios";
import moment from "moment";
import Cookies from 'universal-cookie';
import { LEGACY_URL, ADMIN_API } from "../../variables/switching";
import { FETCH_ACTION } from "../reducers/bookingList";
import queryString from "query-string";
import dealership from "../../pages/data/dealership";
const cookies = new Cookies();

export const fetchBookings = query => dispatch => {
  query.month = moment(query.month, "MM/YY").format("MM-YYYY");
  dispatch({ type: FETCH_ACTION.FETCHING, payload: `${ADMIN_API}/dealers/${query.dealership_id}/billing-excel?month=${query.month}` });
  return axios
    .get(`${ADMIN_API}/dealers/${query.dealership_id}/branches/bookings?month=${query.month}`,{
      headers:{
        Authorization:'Bearer '+cookies.get('advertiser_token')
      }
    })
    .then(res => {
      dispatch({ type: FETCH_ACTION.FETCHED, payload: res.data });
    })
    .catch(err => {
      dispatch({ type: FETCH_ACTION.ERROR, payload: err.message });
    });
};
